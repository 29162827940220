import useDeviceData from "../hooks/useDeviceData";
import { useEffect } from "react";
const TestPage = () => {
  const deviceData = useDeviceData();

  useEffect(() => {
    console.log(deviceData);
  }, [deviceData]);
  return <div>TestPage</div>;
};

export default TestPage;
