import { useParams } from "react-router-dom";
import {
  Button,
  ButtonBase,
  Container,
  Backdrop,
  CircularProgress,
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  useSubmitTestEntryData,
  useSubmitEntryData,
  useSubmitProfilerData,
} from "../../hooks/mutations";
import { hover } from "@testing-library/user-event/dist/hover";
import { Close, ChevronRight } from "@mui/icons-material";
import useDeviceData from "../../../../hooks/useDeviceData";
const EntryTest = () => {
  const deviceData = useDeviceData();
  useEffect(() => {
    // Set the page title using document.title
    document.title = "Pollflow Survey";
  }, []);

  const { data } = useParams();

  const mutation = useSubmitEntryData({
    onSuccess: (data) => {
      if (data.profilers == null && data.redirect != null) {
        window.location.href = data.redirect;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(async () => {
    let userDeviceData = await deviceData.collectData();
    console.log(userDeviceData);
    let sendData = {
      data: data,
      deviceData: userDeviceData,
    };

    mutation.mutate(sendData);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading open={mutation.isLoading} />
      {mutation.isError && <Error data={mutation.error} />}

      {mutation.data && mutation.isSuccess && mutation.data?.profilers && (
        <Profilers
          token={mutation.data?.token}
          panelist_id={mutation.data?.panelist_id}
          profilers={mutation.data?.profilers}
          redirect={mutation.data?.redirect}
        />
      )}
    </Box>
  );
};

export default EntryTest;

const Error = (props) => {
  const { data } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h1">Error</Typography>
      <Typography variant="subtitle1">{data.response.message}</Typography>
    </Box>
  );
};

const Loading = (props) => {
  const { open } = props;
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const Profilers = ({ profilers, redirect, panelist_id, token }) => {
  const [loading, setLoading] = useState(false);

  const mutation = useSubmitProfilerData({
    onSuccess: (data) => {
      window.location.href = redirect;
    },
    onError: (error) => {
      setLoading(false);
    },
  });

  const [answers, setAnswers] = useState([]);
  const [currentProfiler, setCurrentProfiler] = useState(0);

  const submit = (newAnswers) => {
    setLoading(true);
    mutation.mutate({
      answers: newAnswers,
      panelist_id,
      token,
    });
  };

  useEffect(() => {}, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: {
          xs: "100%",
          sm: "100%",
          md: "60%",
          lg: "50%",
          xl: "40%",
        },
      }}
    >
      <Loading open={mutation.isLoading || loading} />
      <Card
        sx={{
          borderRadius: 3,
          boxShadow: "0px 5px 10px #ddd",
          width: "100%",
        }}
      >
        <CardContent
          sx={{
            padding: 4,
          }}
        >
          <p>Utöka din profil så kan vi matcha dig bättre med undersökningar</p>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>
              {currentProfiler + 1}/{profilers.length}
            </h3>

            <Button
              onClick={() => {
                setLoading(true);
                window.location.href = redirect;
              }}
              endIcon={<ChevronRight />}
              variant="outlined"
              sx={{}}
            >
              Gå direkt till undersökningen
            </Button>
          </Box>

          <Profiler
            profiler={profilers[currentProfiler]}
            onSubmit={(profilerAnswers) => {
              let newAnswers = [
                ...answers,
                {
                  profiler_id: profilers[currentProfiler].profiler_id,
                  answers: profilerAnswers,
                },
              ];
              setAnswers(newAnswers);

              if (currentProfiler + 1 < profilers.length) {
                setCurrentProfiler(currentProfiler + 1);
              } else {
                submit(newAnswers);
              }
            }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

const Profiler = ({ profiler, onSubmit = () => {} }) => {
  const [searchText, setSearchText] = useState("");
  const [answers, setAnswers] = useState([]);

  useEffect(() => {}, []);

  const canSubmit = () => {
    if (profiler.type === "single") {
      return answers.length === 1;
    } else if (profiler.type === "multi") {
      return answers.length > 0;
    } else if (profiler.type === "open") {
      return answers.length > 0;
    }
  };

  return (
    <Box sx={{}}>
      <h3 variant="h5">{profiler.question}</h3>
      <Box
        sx={{
          marginBottom: 8,
        }}
      >
        <TextField
          variant="outlined"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          placeholder="Sök..."
          style={{
            width: "100%",
          }}
        />

        {profiler.type === "single" && (
          <SingleChoice
            profiler={profiler}
            answers={answers}
            setAnswers={setAnswers}
          />
        )}
        {profiler.type === "multi" && (
          <MultipleChoice
            searchText={searchText}
            profiler={profiler}
            answers={answers}
            setAnswers={setAnswers}
          />
        )}
        {profiler.type === "open" && <Open profiler={profiler} />}
      </Box>

      <Button
        onClick={() => {
          onSubmit(answers);
          setAnswers([]);
        }}
        fullWidth
        variant="contained"
        sx={{
          position: "fixed",
          bottom: 20,
          height: 50,
          width: "100%",
          backgroundColor: "#3A33B5",
          "&:hover": {
            backgroundColor: "#3A33B5",
          },
          boxShadow: "0px 5px 50px white !important",
          width: {
            xs: "calc(100% - 64px)",
            sm: "calc(100% - 64px)",
            md: "calc(60% - 64px)",
            lg: "calc(50% - 64px)",
            xl: "calc(40% - 64px)",
          },
        }}
        disabled={!canSubmit()}
      >
        Skicka {answers.length > 0 ? "(" + answers.length + " svar)" : ""}
      </Button>

      {answers.length > 0 && (
        <IconButton
          variant="contained"
          onClick={() => {
            setAnswers([]);
          }}
          sx={{
            position: "fixed",
            bottom: 80,

            backgroundColor: "#3A33B5 !important",

            width: 50,
            height: 50,
          }}
        >
          <Close
            size={20}
            sx={{
              color: "white !important",
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

const SingleChoice = ({ profiler, answers = [], setAnswers = () => {} }) => {
  return (
    <Box>
      {profiler.answers.map((answer) => {
        return (
          <ChoiceButton
            title={answer.answer}
            isActive={answers.includes(answer.answer_id)}
            onClick={() => {
              setAnswers([answer.answer_id]);
            }}
          />
        );
      })}
    </Box>
  );
};

const MultipleChoice = ({
  searchText,
  profiler,
  answers = [],
  setAnswers = () => {},
}) => {
  return (
    <Box>
      {profiler.answers
        .filter((answer) =>
          answer.answer.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((answer) => {
          return (
            <ChoiceButton
              title={answer.answer}
              isActive={answers.includes(answer.answer_id)}
              onClick={() => {
                if (answers.includes(answer.answer_id)) {
                  setAnswers(answers.filter((a) => a !== answer.answer_id));
                } else {
                  setAnswers([...answers, answer.answer_id]);
                }
              }}
            />
          );
        })}
    </Box>
  );
};

const Open = ({ profiler }) => {
  return <Box></Box>;
};

const ChoiceButton = ({ onClick = () => {}, title = "", isActive = false }) => {
  return (
    <Button
      variant="outlined"
      sx={{
        width: "100%",
        minHeight: 50,
        marginTop: 1,
        backgroundColor: isActive ? "white" : "rgba(0,0,0,0.02) !important",
        border: isActive ? "2px solid #3A33B5" : "0px solid #ccc",
        ":hover": {
          color: "white",
        },
        color: "black !important",
      }}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};
