import { useMutation } from "@tanstack/react-query";
import {
  submitEntryData,
  submitTestEntryData,
  submitProfilerData,
} from "../api";

export const useSubmitEntryData = (options = {}) => {
  const mutation = useMutation(submitEntryData, options);

  return mutation;
};

export const useSubmitTestEntryData = (options = {}) => {
  const mutation = useMutation(submitTestEntryData, options);

  return mutation;
};

export const useSubmitProfilerData = (options) => {
  const mutation = useMutation(submitProfilerData, options);

  return mutation;
};
