import React, { useEffect, useState } from "react";
import UAParser from "ua-parser-js";

const useDeviceData = () => {
  const [deviceData, setDeviceData] = useState({});

  const collectData = async () => {
    const parser = new UAParser();
    const userAgent = navigator.userAgent;

    // Browser information
    const browser = parser.getBrowser();
    const browserName = browser.name || "Unknown";
    const browserVersion = browser.version || "Unknown";

    // OS information
    const os = parser.getOS();
    const osName = os.name || "Unknown";
    const osVersion = os.version || "Unknown";

    // Device information
    const device = parser.getDevice();
    const deviceType = device.type || "desktop"; // Default to desktop if no type is provided
    const deviceVendor = device.vendor || "Unknown";
    const deviceModel = device.model || "Unknown";

    // Screen dimensions
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    // Locale and timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const locale = navigator.language;

    // Network information (if supported by browser)
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    const networkType = connection?.effectiveType || "Unknown";

    // Geolocation (optional, requires user consent)

    // Referrer URL
    const referrerUrl = document.referrer;

    // Cookies enabled
    const cookiesEnabled = navigator.cookieEnabled;

    // Set collected data
    return {
      userAgent,
      browserName,
      browserVersion,
      osName,
      osVersion,
      deviceType,
      deviceVendor,
      deviceModel,
      timezone,
      locale,
      networkType,
      referrerUrl,
      cookiesEnabled,
      screenWidth,
      screenHeight,
    };
  };

  useEffect(async () => {
    let data = await collectData();

    setDeviceData(data);
  }, []);

  return { deviceData, collectData };
};

export default useDeviceData;
