import * as api from "@api";

export const submitEntryData = (data) => {
  return api.post("/entry", {
    data,
  });
};

export const submitTestEntryData = (data) => {
  return api.post("entrytest", {
    data,
  });
};

export const submitProfilerData = (data) => {
  return api.post(
    "/panelists/" + data.panelist_id + "/profilers",
    {
      answers: data.answers,
    },
    data.token
  );
};
